import styled from "styled-components"
import { media } from "../../../../utils/media-queries"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: fit-content;

  padding: 0 16px;

  background-color: ${({ theme }) => theme.palette.gray[50]};

  @media ${media.medium} {
    width: auto;
    flex: 1;
  }
`

export const Table = styled.table`
  padding: 0 0 8px 0;
  width: 100%;

  background-color: ${({ theme }) => theme.palette.gray[50]};
`
