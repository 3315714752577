import { useFieldArray, useForm, useWatch } from "react-hook-form"
import { Input } from "../../../../../components/ui/Input"
import {
  assetSchema,
  AssetSchema,
  SCHEMA_ERRORS,
  getDefaultValues,
} from "../../utils/schema"
import {
  BasicInfo,
  AssetInfo,
  Content,
  PropertiesMessage,
  PropertiesFields,
  InputContainer,
  InputWrapper,
} from "./styles"
import { useEffect, useMemo } from "react"
import { Divider } from "../../../../../components/ui/Divider"
import { TextArea } from "../../../../../components/ui/TextArea"
import { AssetFormSkeleton } from "./AssetForm.skeleton"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { Text } from "../../../../../components/ui/Text"
import { useFetchAssetTypesQuery } from "../../../../../store/store"
import { InputSelect } from "../../../../../components/ui/InputSelect"
import { useProperties } from "../../utils/useProperties"
import { LinkSensorForm } from "../LinkSensorForm"
import { FlexContainer } from "../../../../../components/ui/FlexContainer"
import { zodResolver } from "@hookform/resolvers/zod"
import { AssetDetailsData } from "../../../../../utils/entities/assets"
import { Button } from "../../../../../components/ui/Button"

interface AssetFormProps {
  defaultDetails?: AssetDetailsData
  isRoot?: boolean
  isDisabled: boolean
  isCreating?: boolean
  handleSubmit: (data: AssetSchema) => Promise<void>
  handleCancel: () => void
}

const AssetForm = ({
  defaultDetails,
  isRoot = false,
  isDisabled,
  isCreating = false,
  handleSubmit,
  handleCancel,
}: AssetFormProps) => {
  const { assets, buttons } = useAppTranslate()

  const form = useForm<AssetSchema>({
    resolver: zodResolver(assetSchema),
  })

  useEffect(() => {
    if (!defaultDetails) return
    form.reset(getDefaultValues(defaultDetails))
  }, [defaultDetails, form])

  const { getDefaultPropertyName, getPropertyTranslation } = useProperties()

  const { data: types, isLoading, isError } = useFetchAssetTypesQuery()

  const getErrorMessage = (message: string | undefined): string | undefined => {
    if (!message) return undefined

    switch (message) {
      case SCHEMA_ERRORS.INVALID_FILE_SIZE:
        return assets.form.errors.invalidFileSize
      case SCHEMA_ERRORS.INVALID_IMAGE_TYPE:
        return assets.form.errors.invalidFileType
      case SCHEMA_ERRORS.REQUIRED:
        return assets.form.errors.required
      default:
        return assets.form.errors.default
    }
  }

  const {
    control,
    setFocus,
    register,
    formState: { errors },
  } = form

  const { fields, append, remove } = useFieldArray({
    control,
    name: "properties",
  })

  const type = useWatch({
    control,
    name: "type",
  })

  const date = useWatch({
    control,
    name: "linkedAt",
  })

  useEffect(() => {
    if (!type) return
    if (!types) return

    remove()

    const assetType = types.find((item) => item.id === Number(type))

    if (!assetType) return

    Object.keys(assetType.properties).forEach((property) => {
      append({ property, value: "" })
    })
  }, [remove, append, type, fields.length, setFocus, types])

  useEffect(() => {
    if (fields.length === 0) return
    setFocus("type")
  }, [fields, setFocus])

  const handleSelectSensor = (id: number) => {
    form.setValue("sensorId", id)
  }

  const options = useMemo(() => {
    if (!types) return []

    return types.map((type) => type.id)
  }, [types])

  const findTypeName = (type: number): string => {
    if (!types) return ""

    const assetType = types.find((item) => item.id === type)

    if (!assetType) return ""

    return assetType.name
  }

  const renderLabel = (type: number): string => {
    const defaultname = getDefaultPropertyName(type)
    if (defaultname) return defaultname

    return findTypeName(type)
  }

  const getPropertyText = (property: string): string => {
    const translatedProperty = getPropertyTranslation(Number(type), property)
    if (translatedProperty) return translatedProperty

    return property
  }

  if (isLoading) return <AssetFormSkeleton />

  if (!types || isError)
    return (
      <FlexContainer gap={12}>
        <Text>{assets.form.errors.types}</Text>
        <Button variant='secondary' onClick={handleCancel}>
          {buttons.back}
        </Button>
      </FlexContainer>
    )

  return (
    <>
      <AssetInfo>
        {/* <ImageContainer
          editable
          {...register("image")}
          error={getErrorMessage(errors.image?.message as string)}
          onReset={() => clearErrors("image")}
        /> */}

        <BasicInfo>
          <Text fontSize='md' fontWeight='bold'>
            {assets.form.titles.informations}
          </Text>
          <InputContainer>
            <InputWrapper>
              <Input
                label={assets.form.name}
                placeholder={assets.form.namePlaceholder}
                error={getErrorMessage(errors.name?.message)}
                {...register("name")}
              />
            </InputWrapper>

            {!isRoot && (
              <InputWrapper>
                <InputSelect
                  label={assets.details.type}
                  placeholder={assets.form.addTypePlaceholder}
                  options={options}
                  error={getErrorMessage(errors.type?.message)}
                  renderLabel={(option) => renderLabel(Number(option))}
                  {...register("type")}
                />
              </InputWrapper>
            )}
          </InputContainer>
        </BasicInfo>
      </AssetInfo>

      <TextArea
        label={assets.details.description}
        rows={10}
        placeholder={assets.form.descriptionPlaceholder}
        error={getErrorMessage(errors.description?.message)}
        {...register("description")}
      />

      <Divider />

      <Content>
        <Text fontSize='md' fontWeight='bold'>
          {assets.form.titles.properties}
        </Text>

        {!type && (
          <PropertiesMessage>
            <Text fontSize='sm' fontWeight='regular'>
              {assets.form.propertiesPlaceholder}
            </Text>
          </PropertiesMessage>
        )}

        {fields.length === 0 && type && (
          <PropertiesMessage>
            <Text fontSize='sm' fontWeight='regular'>
              {assets.form.propertiesEmpty}
            </Text>
          </PropertiesMessage>
        )}

        {fields.length > 0 && (
          <PropertiesFields>
            {fields.map((field, index) => {
              return (
                <Input
                  key={field.id}
                  label={getPropertyText(field.property)}
                  placeholder={`${getPropertyText(field.property)}`}
                  {...register(`properties.${index}.value`)}
                />
              )
            })}
          </PropertiesFields>
        )}
      </Content>

      <Divider />

      {isCreating && (
        <Content>
          <Text fontSize='md' fontWeight='bold'>
            Sensor
          </Text>

          <LinkSensorForm
            onCheck={(id) => handleSelectSensor(Number(id))}
            dateInputProps={register("linkedAt")}
            dateInputError={errors.linkedAt && assets.form.errors.required}
            dateInputIsEmpty={!date}
          />
          <Text color='error.main' fontSize='xs'>
            {errors.sensorId && assets.form.errors.required}
          </Text>
        </Content>
      )}

      <FlexContainer direction='row' justify='space-between' fullWidth>
        <Button variant='secondary' onClick={handleCancel}>
          {buttons.cancel}
        </Button>

        <Button
          variant='primary'
          onClick={form.handleSubmit(handleSubmit)}
          disabled={isLoading || isDisabled}
        >
          {assets.form.saveChanges}
        </Button>
      </FlexContainer>
    </>
  )
}

export { AssetForm, AssetFormSkeleton }
