import styled from "styled-components"

export const InputContainer = styled.div`
  padding: 0px 16px;
  display: flex;
  width: 100%;
  gap: 5px;
`

export const TreeAssetsContainer = styled.div`
  overflow-y: scroll;

  flex: 1 1 auto;

  width: 100%;

  border-top: 1px solid ${(props) => props.theme.palette.gray[300]};
  margin-top: 12px;
`
