import styled from "styled-components"
import { media } from "../../../../../utils/media-queries"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  height: 100%;
  width: 100%;

  @media ${media.large} {
    flex-direction: row;
  }

  @media (min-width: 1300px) {
    flex-direction: row;
    justify-content: end;
  }
`

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 100%;

  @media ${media.large} {
    flex-direction: row;
    align-items: end;
    width: auto;
  }
`

export const InputWrapper = styled.div`
  width: 100%;

  @media ${media.extraLarge} {
    width: 220px;
  }
`

export const FilterMobileWrapper = styled.div`
  display: flex;
  gap: 12;
  width: 100%;

  @media ${media.large} {
    width: auto;
  }
`

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: end;
  gap: 8px;
  width: 100%;

  @media ${media.large} {
    width: auto;
  }
`
