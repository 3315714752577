import { Text } from "../../../ui/Text"
import { Content, TableWrapper, Header } from "./styles"
import { AssetCard } from "../AssetCard"
import { Record } from "phosphor-react"
import { FlexContainer } from "../../../ui/FlexContainer"
import { ServiceConfigured } from "../../../../utils/entities/assets"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { servicesTypes } from "../../../../store/api/utils"
import { HDR_SERVICES_TYPE } from "hdr-process-data"

export interface Asset {
  id: number
  name: string
  path: string
  services: ServiceConfigured[]
}

interface AssetsTableProps {
  assets: Asset[]
  handleRemoveAsset: (id: number) => void
  handleIsServiceDisabled(service: ServiceConfigured): boolean
  hideHealthService: boolean
  hasAxes: boolean
}

export const AssetsTable = ({
  assets,
  handleRemoveAsset,
  handleIsServiceDisabled,
  hideHealthService,
  hasAxes,
}: AssetsTableProps) => {
  const {
    components: {
      configureAssets: { table },
    },
  } = useAppTranslate()

  const getServices = (asset: Asset) => {
    const aux: Record<number, { isHide: boolean; isDisabled: boolean }> = {}

    asset.services.forEach(
      (service) =>
        (aux[service.type] = {
          isDisabled: handleIsServiceDisabled(service),
          isHide: false,
        })
    )

    servicesTypes.forEach((type) => {
      if (!aux[type]) {
        aux[type] = {
          isDisabled: true,
          isHide: type === HDR_SERVICES_TYPE.health ? hideHealthService : true,
        }
      }
    })

    return Object.entries(aux).map(([type, { isHide, isDisabled }]) => ({
      type: parseInt(type),
      isHide,
      isDisabled,
    }))
  }

  return (
    <Content>
      <Header>
        <Text fontWeight='semibold' color='gray.800'>
          {table.title}
        </Text>
      </Header>

      <TableWrapper>
        {!assets.length && (
          <FlexContainer padding='16px' justify='center' fullHeight>
            <Text fontWeight='medium' color='gray.800'>
              {table.empty}
            </Text>
          </FlexContainer>
        )}

        {!!assets.length &&
          assets.map((asset) => {
            return (
              <AssetCard
                key={asset.id}
                hasAxes={hasAxes}
                path={asset.path}
                onRemoveAsset={() => handleRemoveAsset(asset.id)}
                services={getServices(asset)}
              />
            )
          })}
      </TableWrapper>
    </Content>
  )
}
