import { Fragment } from "react/jsx-runtime"
import { FlexContainer } from "../../../ui/FlexContainer"
import { ServiceName } from "../ServiceName"
import { Container, Table } from "./styles"
import { ServiceItem } from "../ServiceItem"
import { Trash } from "phosphor-react"
import theme from "../../../../styles/theme"
import { HDR_SERVICES_TYPE } from "hdr-process-data"
import {
  getHDRServiceAcronym,
  getServiceAxes,
} from "../../../../utils/hdr_services_name"

interface AssetCardProps {
  path: string
  services: {
    type: HDR_SERVICES_TYPE
    isHide: boolean
    isDisabled: boolean
  }[]
  onRemoveAsset: () => void
  hasAxes: boolean
}

export const AssetCard = ({
  path,
  services,
  hasAxes,
  onRemoveAsset,
}: AssetCardProps) => {
  return (
    <Container>
      <FlexContainer>
        <ServiceName fullPath={path} />

        <Table>
          <tbody>
            <Fragment>
              <tr>
                {services.map(({ type, isHide, isDisabled }) => (
                  <td
                    key={type}
                    style={{
                      width: "fit-content",
                      paddingRight: 8,
                    }}
                  >
                    <ServiceItem
                      axes={getServiceAxes(type, "A")}
                      hasAxes={hasAxes}
                      isDisabled={isDisabled}
                      isHide={isHide}
                      label={getHDRServiceAcronym(type)}
                    />
                  </td>
                ))}
              </tr>
            </Fragment>
          </tbody>
        </Table>
      </FlexContainer>

      <Trash
        size={24}
        color={theme.palette.gray[800]}
        weight='bold'
        onClick={onRemoveAsset}
      />
    </Container>
  )
}
