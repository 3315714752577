import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { thorBaseQuery } from "../utils"
import {
  AssetData,
  AssetDetailsData,
  AssetType,
} from "../../../utils/entities/assets"
import {
  transformAssetDetails,
  transformAssetsChildren,
  transformAssetsStructure,
} from "./transforms"
import {
  CreateAssetData,
  FetchUnlinkSensors,
  LinkSensorData,
  UpdateAssetData,
  FetchUnlinkSensorsResponse,
  UnlinkSensorData,
  FetchAssetsTreeData,
} from "./entities"
import {
  onCreateQueryStarted,
  onDeleteQueryStarted,
  onUnlinkSensorQueryStarted,
  onUpdateQueryStarted,
} from "./updates"
import { AssetTreeData } from "../../../components/ui/AssetsTree/entities"

export const assetsApi = createApi({
  reducerPath: "assetsApi",
  baseQuery: thorBaseQuery(),
  tagTypes: ["LIST_SENSORS", "DETAILS", "FETCH_ASSETS"],
  endpoints: (build) => ({
    fetchAssets: build.query<AssetTreeData, FetchAssetsTreeData>({
      query: ({ services }) => {
        return {
          url: "/v1/assets/structure",
          method: "GET",
          params: {
            services,
          },
        }
      },
      transformResponse: transformAssetsStructure,
      providesTags: ["FETCH_ASSETS"],
    }),

    fetchAssetChildren: build.query<AssetData[] | undefined, number>({
      query: (_) => {
        return {
          url: `/v1/assets/structure`,
          method: "GET",
        }
      },
      transformResponse: transformAssetsChildren,
    }),

    fetchAssetDetails: build.query<AssetDetailsData, number>({
      query: (assetId) => {
        return {
          url: `/v1/assets/${assetId}`,
          method: "GET",
        }
      },
      transformResponse: transformAssetDetails,
      providesTags: (result) => {
        return result ? [{ type: "DETAILS", id: result.id }] : []
      },
    }),

    createAsset: build.mutation<AssetDetailsData, CreateAssetData>({
      query: ({ parentId, data }) => {
        return {
          url: "/v1/assets",
          method: "POST",
          body: {
            properties: Object.fromEntries(
              data.properties.map(({ property, value }) => [property, value])
            ),
            assetSourceId: parentId,
            description: data.description,
            name: data.name,
            sensorProvId: data.sensorId,
            linkedAt: data.linkedAt
              ? new Date(data.linkedAt).getTime().toString()
              : undefined,
            typeId: Number(data.type),
          },
        }
      },
      transformResponse: transformAssetDetails,
      onQueryStarted: onCreateQueryStarted,
      invalidatesTags: ["LIST_SENSORS"],
    }),

    deleteAsset: build.mutation<void, number>({
      query: (assetId) => {
        return {
          url: `/v1/assets/${assetId}`,
          method: "DELETE",
        }
      },
      onQueryStarted: onDeleteQueryStarted,
      invalidatesTags: ["LIST_SENSORS"],
    }),

    updateAsset: build.mutation<AssetDetailsData, UpdateAssetData>({
      query: ({ id, data }) => {
        return {
          url: `/v1/assets/${id}`,
          method: "PATCH",
          body: {
            name: data.name,
            description: data.description,
          },
        }
      },
      onQueryStarted: onUpdateQueryStarted,
      transformResponse: transformAssetDetails,
    }),

    fetchUnlinkedSensors: build.query<
      FetchUnlinkSensorsResponse,
      FetchUnlinkSensors
    >({
      query: (params) => {
        return {
          url: "/v1/assets/sensors/unlinked",
          params: {
            offset: params.offset,
            limit: params.limit,
            search: params.search,
            order_by: params.orderBy,
            sort_by: params.sortBy,
          },
          method: "GET",
        }
      },
      providesTags: ["LIST_SENSORS"],
    }),

    linkSensor: build.mutation<void, LinkSensorData>({
      query: ({ id, linkedAt, sensorId }) => {
        return {
          url: `/v1/assets/${id}/sensors`,
          method: "POST",
          body: {
            linkedAt,
            sensorProvId: sensorId,
          },
        }
      },

      invalidatesTags: (result, error, { id }) => [
        "LIST_SENSORS",
        { type: "DETAILS", id },
      ],
    }),

    deleteLinkSensor: build.mutation<void, UnlinkSensorData>({
      query: ({ id, sensorId }) => {
        return {
          url: `/v1/assets/${id}/sensors/${sensorId}`,
          method: "DELETE",
        }
      },
      onQueryStarted: onUnlinkSensorQueryStarted,
      invalidatesTags: ["LIST_SENSORS"],
    }),

    fetchAssetTypes: build.query<AssetType[], void>({
      query: (_) => {
        return {
          url: "/v1/assets/types",
          method: "GET",
        }
      },
    }),
  }),
})

export const {
  useFetchAssetDetailsQuery,
  useFetchAssetsQuery,
  useCreateAssetMutation,
  useDeleteAssetMutation,
  useUpdateAssetMutation,
  useFetchUnlinkedSensorsQuery,
  useLinkSensorMutation,
  useFetchAssetChildrenQuery,
  useDeleteLinkSensorMutation,
  useFetchAssetTypesQuery,
} = assetsApi
