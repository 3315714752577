import { HTMLAttributes, ReactNode } from "react"
import { TreeItem, TreeItemRenderContext } from "react-complex-tree"
import { AssetIcon } from "../AssetIcon"
import {
  AddAssetButton,
  ContainerWithChildren,
  Content,
  ExpandAssetButton,
  Wrapper,
  ItemContainer,
} from "./styles"
import { BsPlus } from "react-icons/bs"
import { CaretDown } from "phosphor-react"
import { BaseAssetData } from "../AssetsTree/entities"

export interface TreeItemRendererProps
  extends Omit<HTMLAttributes<HTMLLIElement>, "title"> {
  item: TreeItem<BaseAssetData>
  context: TreeItemRenderContext<"expandedItems">
  depth: number
  children?: ReactNode
  showAddIcon: boolean
  onSelectItem?: (item: TreeItem<BaseAssetData>) => void
  onClickAddIcon: (item: TreeItem<BaseAssetData>) => void
}

export const TreeItemRenderer = ({
  item,
  context,
  children,
  depth,
  showAddIcon,
  onSelectItem,
  onClickAddIcon,
}: TreeItemRendererProps) => {
  const hasChildren = !!(item.children && item.children.length)

  return (
    <Wrapper {...context.itemContainerWithChildrenProps}>
      <ItemContainer
        {...context.itemContainerWithoutChildrenProps}
        $selected={context.isSelected ?? false}
        onClick={(e) => {
          if (onSelectItem) onSelectItem(item)
          context.selectItem()
        }}
      >
        <Content
          {...context.interactiveElementProps}
          $depth={depth}
          onClick={() => {}}
        >
          {hasChildren && context.isExpanded !== undefined && (
            <ExpandAssetButton $expanded={context.isExpanded}>
              <CaretDown
                size={16}
                onClick={(e) => {
                  if (context.isExpanded) {
                    context.collapseItem()
                  } else {
                    context.expandItem()
                  }

                  e.stopPropagation()
                }}
              />
            </ExpandAssetButton>
          )}

          <AssetIcon typeId={item.data.typeId} />
          {item.data.name}
        </Content>

        {showAddIcon && (
          <AddAssetButton>
            <BsPlus size={20} onClick={() => onClickAddIcon(item)} />
          </AddAssetButton>
        )}
      </ItemContainer>
      {context.isExpanded && (
        <ContainerWithChildren>{children}</ContainerWithChildren>
      )}
    </Wrapper>
  )
}
