import styled from "styled-components"

export const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 0 0 0;
  gap: 8px;
  justify-content: start;
  align-items: center;
  width: 100%;
`

export const PathContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 0;
  margin-bottom: 1px;
`

export const Form = styled.form`
  display: flex;
  width: 100%;
`

export const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 4px;

  border: 1px solid ${({ theme }) => theme.palette.gray[400]};
  border-radius: 4px;
`
