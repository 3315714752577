import { useState } from "react"
import { AssetsTree } from "../ui/AssetsTree"
import { Asset, AssetsTable } from "./components/AssetsTable"
import { Text } from "../ui/Text"
import { Content, TitleContainer, TreeContainer } from "./styles"
import { BaseAssetData } from "../ui/AssetsTree/entities"
import { ServiceConfigured } from "../../utils/entities/assets"
import { TreeItem } from "react-complex-tree"
import { useAppTranslate } from "../../translate/useAppTranslate"

interface ConfigureAssetsProps {
  handleIsServiceDisabled(service: ServiceConfigured): boolean
  hideHealthService?: boolean
  hasAxes?: boolean
}

export const ConfigureAssets = ({
  handleIsServiceDisabled,
  hideHealthService = false,
  hasAxes = false,
}: ConfigureAssetsProps) => {
  const {
    assets: { tree },
  } = useAppTranslate()

  const [assets, setAssets] = useState<Asset[]>([])

  const showAddIcon = (item: TreeItem<BaseAssetData>) => {
    const alreadyAdded = assets.find((asset) => asset.id === item.data.id)

    return (
      item.data.services !== undefined &&
      item.data.services.length > 0 &&
      !alreadyAdded
    )
  }

  const handleSelectAsset = (item: TreeItem<BaseAssetData>) => {
    setAssets((prev) => [
      ...prev,
      {
        id: item.data.id,
        name: item.data.name,
        services: item.data.services ?? [],
        path: item.data.path ?? "",
      },
    ])
  }

  const handleRemoveAsset = (id: number) => {
    setAssets((prev) => prev.filter((asset) => asset.id !== id))
  }

  return (
    <Content>
      <TreeContainer>
        <TitleContainer>
          <Text fontWeight='semibold' color='gray.800'>
            {tree.title}
          </Text>
        </TitleContainer>

        <AssetsTree
          showAddIcon={showAddIcon}
          onClickAddIcon={handleSelectAsset}
          services
        />
      </TreeContainer>

      <AssetsTable
        assets={assets}
        hasAxes={hasAxes}
        handleRemoveAsset={handleRemoveAsset}
        handleIsServiceDisabled={handleIsServiceDisabled}
        hideHealthService={hideHealthService}
      />
    </Content>
  )
}
