import styled from "styled-components"

export const Container = styled.div<{ $hide: boolean }>`
  display: flex;
  justify-content: start;

  width: fit-content;

  visibility: ${({ $hide }) => ($hide ? "hidden" : "visible")};
`

export const TooltipWrapper = styled.div`
  position: relative;
`

export const Tooltip = styled.div`
  position: absolute;
  top: 22px;

  background-color: ${({ theme }) => theme.palette.gray[50]};

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.gray[400]};

  padding: 8px;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`
