import { HDR_SERVICES_TYPE } from "hdr-process-data"

export const getHDRServiceAcronym = (type: HDR_SERVICES_TYPE) => {
  switch (type) {
    case HDR_SERVICES_TYPE.health:
      return "HEALTH"
    case HDR_SERVICES_TYPE.temp:
      return "TEMP"
    case HDR_SERVICES_TYPE.tempMMM:
      return "TEMP_MMM"
    case HDR_SERVICES_TYPE.rms2:
      return "RMS2"
    case HDR_SERVICES_TYPE.rmms:
      return "RMMS"
    case HDR_SERVICES_TYPE.tilt:
      return "TILT"
    case HDR_SERVICES_TYPE.fft:
      return "FFT"
    case HDR_SERVICES_TYPE.accRaw:
      return "ACCRAW"
    case HDR_SERVICES_TYPE.gps:
      return "GPS"
    case HDR_SERVICES_TYPE._4t20:
      return "4T20"
    case HDR_SERVICES_TYPE.ntc:
      return "NTC"
    case HDR_SERVICES_TYPE.pot:
      return "POT"
  }
}

export const getServiceAxes = (
  type: HDR_SERVICES_TYPE,
  config: AxleConfig | ChannelConfig | TiltConfig
): string[] => {
  switch (type) {
    case HDR_SERVICES_TYPE.health:
    case HDR_SERVICES_TYPE.temp:
    case HDR_SERVICES_TYPE.tempMMM:
    case HDR_SERVICES_TYPE.gps:
      return []
    case HDR_SERVICES_TYPE.rms2:
    case HDR_SERVICES_TYPE.rmms:
    case HDR_SERVICES_TYPE.fft:
    case HDR_SERVICES_TYPE.accRaw:
      return getAxes(config as AxleConfig)
    case HDR_SERVICES_TYPE.tilt:
      return getTilt(config as TiltConfig)
    case HDR_SERVICES_TYPE._4t20:
    case HDR_SERVICES_TYPE.ntc:
    case HDR_SERVICES_TYPE.pot:
      return getChannels(config as ChannelConfig)
  }
}

type AxleConfig = "all" | "X" | "Y" | "Z"
type ChannelConfig = "AB" | "A" | "B"
type TiltConfig = "PITCH" | "ROLL"

const getAxes = (config: AxleConfig) => {
  if (config === "all") {
    return ["X", "Y", "Z", "MODULE"]
  }

  return [config]
}

const getChannels = (config: ChannelConfig) => {
  if (config === "AB") {
    return ["A", "B"]
  }

  return [config]
}

const getTilt = (config: TiltConfig) => {
  return [config]
}
