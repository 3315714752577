import { Skeleton } from "@mui/material"
import {
  AssetInfo,
  BasicInfo,
  PropertiesFields,
  PropertiesContainer,
} from "./styles"
import { Divider } from "../../../../../components/ui/Divider"
import { InputSkeleton } from "../../../../../components/ui/Input"

export interface SensorRow {
  id: number | string
  name: string
  serialNumber: string
}

export const AssetFormSkeleton = () => {
  return (
    <>
      <AssetInfo>
        <Skeleton variant='rectangular' width={273} height={200} />

        <BasicInfo>
          <InputSkeleton />
          <InputSkeleton />
        </BasicInfo>
      </AssetInfo>

      <Divider />

      <>
        <Skeleton variant='text' width={64} height={24} />
        <Skeleton variant='rounded' height={156} />
      </>

      <Divider />

      <PropertiesContainer>
        <PropertiesFields>
          <InputSkeleton />
          <InputSkeleton />
          <InputSkeleton />
        </PropertiesFields>
      </PropertiesContainer>

      <Divider />
    </>
  )
}
