import styled from "styled-components"
import { media } from "../../utils/media-queries"

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  height: 100%;
  width: 100%;

  @media ${media.extraLarge} {
    flex-direction: row;
  }
`

export const TreeContainer = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${(props) => props.theme.palette.gray[50]};
  border: 1px solid ${(props) => props.theme.palette.gray[400]};
  border-radius: 4px;

  @media ${media.extraLarge} {
    min-width: 300px;
  }
`

export const TitleContainer = styled.div`
  padding: 12px 16px;
  margin-bottom: 12px;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray[400]};
`
