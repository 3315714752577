import { CaretDoubleLeft, CaretDoubleRight } from "phosphor-react"
import { IconButton } from "../../../../../../components/ui/IconButton"
import { Text } from "../../../../../../components/ui/Text"
import {
  Container,
  TitleContainer,
  AssetsContainer,
  AssetsHeader,
  IconContainer,
} from "./styles"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"
import { AssetsTree } from "../../../../../../components/ui/AssetsTree"
import { useState } from "react"
import { ModalHedro } from "../../../../../../components/ModalHedro"
import { CreateAsset } from "../../../Create"
import { TreeItem } from "react-complex-tree"
import { BaseAssetData } from "../../../../../../components/ui/AssetsTree/entities"

interface CollapseTreeProps {
  onCollapse: () => void
  onChangeAssetDetails: (id: number) => void
  open: boolean
}

export const CollapseTree = ({
  onCollapse,
  onChangeAssetDetails,
  open,
}: CollapseTreeProps) => {
  const { assets } = useAppTranslate()

  const [openModalCreate, setOpenModalCreate] = useState(false)

  const [parentId, setParentId] = useState<number | undefined>()

  const handleClickAddIcon = (item: TreeItem<BaseAssetData>) => {
    setParentId(item.data.id)
    setOpenModalCreate(true)
  }

  const handleCancelCreate = () => {
    setParentId(undefined)
    setOpenModalCreate(false)
  }

  return (
    <>
      {openModalCreate && (
        <ModalHedro
          onClose={handleCancelCreate}
          title={assets.modal.createAsset}
          size='xl'
        >
          {parentId && (
            <CreateAsset assetId={parentId} handleCancel={handleCancelCreate} />
          )}
        </ModalHedro>
      )}

      <Container>
        <AssetsContainer open={open}>
          <AssetsHeader>
            <CaretDoubleLeft
              onClick={onCollapse}
              size={24}
              style={{ cursor: "pointer" }}
            />
          </AssetsHeader>

          <TitleContainer>
            <Text fontWeight='semibold' color='gray.800'>
              {assets.tree.title}
            </Text>
          </TitleContainer>

          <AssetsTree
            onClick={(item) => onChangeAssetDetails(item.data.id)}
            onClickAddIcon={handleClickAddIcon}
          />
        </AssetsContainer>

        <IconContainer open={open}>
          <IconButton
            icon={<CaretDoubleRight size={24} />}
            variant='secondary'
            onClick={onCollapse}
          />
        </IconContainer>
      </Container>
    </>
  )
}
