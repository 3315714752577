import { RefObject, useCallback } from "react"
import { TreeItemIndex, TreeRef } from "react-complex-tree"
import { CustomDataProvider } from "./CustomDataProvider"

export const TreeContentController = (
  dataProvider: CustomDataProvider,
  search: string,
  tree: RefObject<TreeRef<string>>
) => {
  const findItemPath = useCallback(
    async (
      search: string,
      searchRoot: TreeItemIndex = "master"
    ): Promise<string[] | null> => {
      const item = await dataProvider.getTreeItem(searchRoot)

      if (!item) return null

      if (item.data.name.toLowerCase().includes(search.toLowerCase())) {
        return [item.index.toString()]
      }

      const searchedItems = await Promise.all(
        item.children?.map((child) => findItemPath(search, child)) ?? []
      )

      const result = searchedItems.find((item) => item !== null)

      if (!result) return null

      return [item.index.toString(), ...result]
    },
    [dataProvider]
  )

  const findItemPathById = useCallback(
    async (
      id: string,
      searchRoot: TreeItemIndex = "master"
    ): Promise<string[] | null> => {
      const item = await dataProvider.getTreeItem(searchRoot)

      if (!item) return null

      if (item.index.toString() === id) {
        return [item.index.toString()]
      }

      const searchedItems = await Promise.all(
        item.children?.map((child) => findItemPathById(id, child)) ?? []
      )

      const result = searchedItems.find((item) => item !== null)

      if (!result) return null

      return [item.index.toString(), ...result]
    },
    [dataProvider]
  )

  const find = useCallback(() => {
    if (!search) return

    findItemPath(search).then((path) => {
      if (!path) return

      tree.current?.expandSubsequently(path).then(() => {
        const last = path.at(-1)

        if (!last) return

        try {
          tree.current?.selectItems([last])
          tree.current?.focusItem(last)
        } catch (error) {
          console.error(`Error on asset tree item index ${last}`, error)
        }
      })
    })
  }, [findItemPath, search, tree])

  const findById = useCallback(
    async (id: string | undefined) => {
      if (!id) return null

      const path = await findItemPathById(id)
      if (!path) return null

      try {
        if (!tree.current) return null

        const last = path.at(-1)
        if (!last) return null

        await tree.current.expandSubsequently(path.slice(0, -1))

        return path
      } catch (error) {
        console.error(`Error on asset tree item index ${id}`, error)
        return null
      }
    },
    [findItemPathById, tree]
  )

  return {
    find,
    findById,
  }
}
