import { useState } from "react"
import { ModalHedro } from "../../../../components/ModalHedro"
import { AssetsTree } from "../../../../components/ui/AssetsTree"
import { FlexContainer } from "../../../../components/ui/FlexContainer"
import { Asset, AssetsTable } from "./components/AssetsTable"
import { Content, TreeContainer } from "./styles"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { Text } from "../../../../components/ui/Text"
import { ChartType } from "../../../../components/Chart/entities/ChartData"
import { useFetchAssetsQuery } from "../../../../store/store"
import { TreeItem } from "react-complex-tree"
import { BaseAssetData } from "../../../../components/ui/AssetsTree/entities"
import { filterServices } from "../../utils/filterServices"

interface ModalConfigureAssetsProps {
  onClose: () => void
  onBack: () => void
  onAction: () => void
  chartType: ChartType
}

export const ModalConfigureAssets = ({
  onClose,
  onBack,
  onAction,
  chartType,
}: ModalConfigureAssetsProps) => {
  const {
    dashboard: { configureAssets },
  } = useAppTranslate()

  const { data, isError, isLoading } = useFetchAssetsQuery({ services: true })

  const [assets, setAssets] = useState<Asset[]>([])

  const handleSelectAsset = (item: TreeItem<BaseAssetData>) => {
    setAssets((prev) => [
      ...prev,
      {
        id: item.data.id,
        name: item.data.name,
        services: item.data.services ?? [],
        path: "",
      },
    ])
  }

  const handleRemoveAsset = (id: number) => {
    setAssets((prev) => prev.filter((asset) => asset.id !== id))
  }

  const showAddIcon = (item: TreeItem<BaseAssetData>) => {
    let filteredServices = chartType
      ? filterServices(chartType, item.data.services ?? [])
      : item.data.services

    if (filteredServices === undefined) filteredServices = []

    const alreadyAdded = assets.find((asset) => asset.id === item.data.id)

    return filteredServices.length > 0 && !alreadyAdded
  }

  const maxServices = assets.reduce(
    (acc, curr) => Math.max(acc, curr.services.length),
    0
  )

  const isDisabled = isLoading || isError || assets.length === 0

  const size = isError ? "md" : "xl"

  return (
    <ModalHedro
      onClose={onClose}
      onBack={onBack}
      onAction={onAction}
      title={configureAssets.title}
      subtitle={configureAssets.subtitle}
      size={size}
      actionBar={configureAssets.submit}
      disableAction={isDisabled}
    >
      {isError && (
        <FlexContainer>
          <Text>{configureAssets.error}</Text>
        </FlexContainer>
      )}

      {!isError && (
        <Content>
          {data && (
            <TreeContainer>
              <AssetsTree
                showAddIcon={showAddIcon}
                onClickAddIcon={handleSelectAsset}
              />
            </TreeContainer>
          )}

          <AssetsTable
            assets={assets}
            maxServices={maxServices}
            chartType={chartType}
            handleRemoveAsset={handleRemoveAsset}
          />
        </Content>
      )}
    </ModalHedro>
  )
}
