import { NewHeader } from "../../../components/NewHeader"
import { PageContent, PageWrapper } from "../../../components/ui/utils"
import { HeaderActions } from "./components/HeaderActions"
import { HeaderContainer } from "./styles"
import { SensorTable } from "./components/SensorsTable"
import { ReportsContextProvider } from "./context/ReportsContext"
import { useAppTranslate } from "../../../translate/useAppTranslate"
import { useState } from "react"
import { ConfigureAssets } from "../../../components/ConfigureAssets"
import { ServiceConfigured } from "../../../utils/entities/assets"

export const Reports = () => {
  const { lateralMenu } = useAppTranslate()

  const page = {
    title: lateralMenu.routes.reports.title,
    subTitle: lateralMenu.routes.reports.download,
  }

  const [orientedBy, setOrientedBy] = useState<"Sensor" | "Asset">("Sensor")

  const handleIsServiceDisabled = (service: ServiceConfigured): boolean => {
    return !service.isConfigured
  }

  return (
    <ReportsContextProvider>
      <PageWrapper>
        <HeaderContainer>
          <NewHeader page={page} />
          <HeaderActions onChangeOrientedBy={(e) => setOrientedBy(e)} />
        </HeaderContainer>

        <PageContent>
          {orientedBy === "Sensor" && <SensorTable />}
          {orientedBy === "Asset" && (
            <ConfigureAssets
              handleIsServiceDisabled={handleIsServiceDisabled}
            />
          )}
        </PageContent>
      </PageWrapper>
    </ReportsContextProvider>
  )
}
