import { FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query/react"
import { AssetData, AssetDetailsData } from "../../../utils/entities/assets"
import { ApiAssetDetails, ApiAssetTree } from "./entities"
import { AssetTreeData } from "../../../components/ui/AssetsTree/entities"

export const transformAssetDetails = (
  response: ApiAssetDetails
): AssetDetailsData => {
  return {
    createdAt: new Date(response.createdAt).getTime(),
    description: response.description,
    historicalData: [],
    id: response.id,
    isRoot: response.isRoot,
    name: response.name,
    properties: response.properties ? response.properties : {},
    type: { id: response.assetType.id, name: response.assetType.name },
    sensor: response.linkedSensor ? response.linkedSensor : undefined,
  }
}

export const transformAssetsStructure = (response: ApiAssetTree[]) => {
  const tree: AssetTreeData = {}

  const rootChildren: number[] = []

  response.forEach((asset) => {
    if (asset.isRoot) rootChildren.push(asset.id)

    tree[asset.id] = {
      index: asset.id,
      data: {
        path: "/" + asset.name,
        id: asset.id,
        name: asset.name,
        typeId: asset.typeId,
        services:
          asset.sensor && asset.sensor.services
            ? asset.sensor.services.map((service) => {
                return {
                  type: service.type,
                  isConfigured: service.isConfigured,
                  lastData: Math.random() * 100 > 50 ? 123321123 : undefined,
                }
              })
            : undefined,
      },
      canMove: false,
      canRename: false,
      children: asset.children,
      isFolder: asset.children.length > 0,
    }
  })

  tree["master"] = {
    index: "master",
    children: rootChildren,
    data: {
      id: -1,
      name: "Master",
      typeId: 1,
    },
  }

  return tree
}

export const transformAssetsChildren = (
  response: ApiAssetTree[],
  meta: FetchBaseQueryMeta | undefined,
  assetId: number
): AssetData[] | undefined => {
  const find = response.find((asset) => asset.id === assetId)

  if (!find) return undefined

  return response.filter((asset) => find.children.includes(asset.id))
}
