import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { TreeItem } from "react-complex-tree"

export interface AssetType {
  id: number
  name: string
  description: string
  properties: Record<string, string>
}

export interface AssetData {
  id: number
  name: string
  typeId: number
}

export interface TreeAssetItem extends TreeItem<AssetData> {}

export interface AssetTreeData extends Record<string, TreeAssetItem> {}

export enum AssetHistoryDataType {
  CONFIGURATION_CHANGED = 1,
  SENSOR_REMOVED = 2,
  SENSOR_ADDED = 3,
}
export interface AssetHistoryData {
  type: AssetHistoryDataType
  createdAt: number
  description: string
}

export interface AssetPropertiesDTO {
  [key: string]: string
}

export interface ServiceConfigured {
  type: HDR_SERVICES_TYPE
  isConfigured: boolean
  lastData?: number
}

export interface Sensor {
  id: number
  name: string
  serialNumber: string
  linkedAt: number
  services: ServiceConfigured[]
}

export interface AssetDetailsData {
  id: number
  isRoot: boolean
  name: string
  type: {
    id: number
    name: string
  }
  createdAt: number
  properties: AssetPropertiesDTO
  sensor?: Sensor
  description: string
  historicalData: AssetHistoryData[]
}
