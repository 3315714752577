import { Trash } from "phosphor-react"
import { FlexContainer } from "../../../../../../components/ui/FlexContainer"
import { Text } from "../../../../../../components/ui/Text"
import { Content, Table, TableWrapper, Header } from "./styles"
import theme from "../../../../../../styles/theme"
import { ServiceItem } from "../ServiceItem"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"
import { ChartType } from "../../../../../../components/Chart/entities/ChartData"
import { filterServices } from "../../../../utils/filterServices"
import { ServiceName } from "../ServiceName"
import { Fragment } from "react/jsx-runtime"
import { ServiceConfigured } from "../../../../../../utils/entities/assets"

export interface Asset {
  id: number
  name: string
  path: string
  services: ServiceConfigured[]
}

interface AssetsTableProps {
  assets: Asset[]
  maxServices?: number
  chartType: ChartType
  handleRemoveAsset: (id: number) => void
}

export const AssetsTable = ({
  assets,
  maxServices = 0,
  chartType,
  handleRemoveAsset,
}: AssetsTableProps) => {
  const {
    dashboard: { configureAssets },
  } = useAppTranslate()

  return (
    <Content>
      <Header>
        <Text fontWeight='semibold' color='gray.800'>
          {configureAssets.tableTitle}
        </Text>
      </Header>

      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <th colSpan={1}>
                <FlexContainer padding='0 0 0 16px' align='start'>
                  <Text fontWeight='medium' color='gray.800'>
                    {configureAssets.assets}
                  </Text>
                </FlexContainer>
              </th>

              <th colSpan={2}>
                <FlexContainer padding='0 0 0 16px' align='start'>
                  <Text fontWeight='medium' color='gray.800'>
                    {configureAssets.services}
                  </Text>
                </FlexContainer>
              </th>
            </tr>
          </thead>

          <tbody>
            {assets.map((asset) => {
              return (
                <Fragment key={asset.id}>
                  <tr>
                    <td
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      colSpan={maxServices + 2}
                    >
                      <ServiceName fullPath={asset.path} />
                    </td>
                  </tr>

                  <tr>
                    {filterServices(chartType, asset.services).map(
                      ({ type }) => (
                        <td
                          key={type}
                          style={{
                            width: "fit-content",
                          }}
                        >
                          <ServiceItem type={type} axes={[]} />
                        </td>
                      )
                    )}

                    {maxServices > asset.services.length && (
                      <td colSpan={maxServices - asset.services.length} />
                    )}

                    <td>
                      <FlexContainer padding='0 4px 0 16px' align='end'>
                        <Trash
                          size={24}
                          color={theme.palette.error.main}
                          onClick={() => handleRemoveAsset(asset.id)}
                        />
                      </FlexContainer>
                    </td>
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </TableWrapper>
    </Content>
  )
}
